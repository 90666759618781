function toKebabCase(str: string) {
  return str?.replace(/([a-z])([A-Z])/g, '$1-$2')?.toLowerCase();
}

export function generateId(block: any) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { _key, _type } = block;

  if (!_key || !_type)
    throw new Error('Block must have _key and _type properties');

  return {
    id: `${toKebabCase(_type)}_${_key}`,
  };
}
